import React from 'react';
import {
  Box,
  Grommet
} from 'grommet';
import styled, { createGlobalStyle } from "styled-components";
import { GlobalTheme } from './Theme';
import {
  BrowserRouter as Router, Route, Switch
} from "react-router-dom";
import { PageRouter } from './components/PageRouter';
import { HomePage } from './pages/HomePage';

const StyledMainBox = styled(Box)`
  margin-bottom: 10px;
  margin-top: 0;
  margin-left:0;
  margin-right:0;
  padding-top: 0px;
`;

const GlobalStyle = createGlobalStyle`
  *:focus {
      outline: none;
  }
  html, body {
  }
`;


const App = () => {

  return (
    <Router>
      <Grommet theme={GlobalTheme} full>
        <GlobalStyle />
        <Box fill>
          <StyledMainBox direction='row' flex overflow={{ horizontal: 'hidden' }}>
            <Box flex align='center' justify='center'>
              <Switch>
                <Route path="/tools/:name" render={(props) => <PageRouter name={props.match.params["name"]} />} />
                <Route path="/" component={HomePage} />
              </Switch>
            </Box>
          </StyledMainBox>
        </Box>
      </Grommet>
    </Router>
  );

}
export default App;