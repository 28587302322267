import { BaseConfig, BaseStore } from "./BaseStore";

const DefaultColors = ["green", "orange", "blue"];

export interface TokenSortConfig extends BaseConfig {
    zoneCount: number;
    tokenCount: number;
    tokenSets: number;
    tokenColors?: string[];
}

export interface TokenSortZoneConfig {
    text?: string;
}

export interface TokenSortZoneData {
    tokens: TokenData[];
    id: number;
    text?: string;
    startingTokenId?: number;
};

export interface TokenSortState {
    zones: TokenSortZoneData[];
}

export interface TokenData {
    startingZone: number;
    id: number;
    location: number;
    color: string;
}

function range(length: number) {
    return Array.from({ length: length }, (x, i) => i);
}

function buildTokens(startingId: number, count: number, startingZone: number, colors?: string[]): TokenData[] {
    const tokenIds = range(count).map(x => startingId + x);

    colors = colors || DefaultColors;

    const startZoneColorId = Math.abs(startingZone + 1);
    const colorNumber = startZoneColorId % colors.length;
    const color = colors[colorNumber].toLowerCase();
    return tokenIds.map(id => { return { id: id, startingZone: startingZone, location: startingZone, color: color } });
}

export class TokenSortStore implements BaseStore<TokenSortConfig, TokenSortState> {

    private static instance: TokenSortStore = new TokenSortStore();
    public static get Instance() {
        return TokenSortStore.instance;
    }

    stateMap: Map<string, TokenSortState> = new Map();

    get displayName() {
        return "Token Sort";
    }

    public getState(config: TokenSortConfig) {
        if (this.stateMap.has(config.name)) {
            return this.stateMap.get(config.name)!;
        }
        else {

            config.tokenSets = config.tokenSets || 1;
            const freeTokenZones: TokenSortZoneData[] = range(config.tokenSets)
                .map(id => { return { zoneId: -(id + 1), setNum: id + 1, startingTokenId: config.tokenCount * id } })
                .map(x => { return { id: x.zoneId, startingTokenId: x.startingTokenId, tokens: buildTokens(x.startingTokenId, config.tokenCount, x.zoneId, config.tokenColors) } });

            const zones: TokenSortZoneData[] = range(config.zoneCount)
                .map(id => { return { zoneId: id + 1, setNum: id + 1 } })
                .map(x => { return { id: x.zoneId, tokens: [] } });

            const allZones = zones.concat(freeTokenZones);
            const state: TokenSortState = { zones: allZones };
            this.stateMap.set(config.name, state);
            return state;
        }
    }
}