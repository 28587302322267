import React, { useState, ChangeEventHandler } from "react";
import { TextArea, Text, Box } from "grommet";

interface ClickToEditProps {
    initialValue?: string;
    onEndEditing: (value?: string) => void;
    fontSize?: string;

}
export const ClickToEdit: React.FC<ClickToEditProps> = props => {
    const [value, setValue] = useState(props.initialValue);
    const [isEditMode, setEditMode] = useState(false);

    const fontSize = props.fontSize || 'inherit';

    const getIntoEditMode = () => {
        setEditMode(true);
    };

    const getOffEditMode = () => {
        setEditMode(false);
        if (props.onEndEditing) {
            props.onEndEditing(value);
        }
    };

    const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
        setValue(e.target.value);
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
        if (
            e.keyCode === 13 ||
            e.charCode === 13 ||
            e.keyCode === 27 ||
            e.charCode === 27
        ) {
            getOffEditMode();
        }
    };

    return (
        <Box fill
            flex
            data-value={value}
            onClick={getIntoEditMode}
        >
            {isEditMode ? (
                <TextArea
                    style={{ fontSize: fontSize, height: '100%' }}
                    autoFocus
                    value={value}
                    resize={false}
                    plain={true}
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                    onBlur={getOffEditMode}
                />
            ) : (
                    <Text
                        style={{ fontSize: fontSize }}>
                        {value}
                    </Text>
                )}
        </Box>
    );
};