import React from "react";
import { Box, Text, Footer, Image, Paragraph, Grid, Nav, Anchor, ResponsiveContext, Button } from "grommet";
import styled from "styled-components";
import { Switch, Route, useRouteMatch, Link } from "react-router-dom";
import { ToolInfoRoutes } from "./ToolInfoPages";
import { Home } from 'grommet-icons';


const ToolTextLabel = styled(Text)`
  font-size: 20px;
  text-align: center;
  font-weight: bold;
`;

const ImageBox = styled(Box)`
    margin-top: 10px;
`;

const NavBar = styled(Nav)`
    color: white;
    font-size: 15px;
`;

export function HomePage() {

    let { path, url } = useRouteMatch();
    const size = React.useContext(ResponsiveContext);
    const headerSize = size === "xsmall" ? "25px" : "50px";

    return <Box flex fill alignContent="center" direction="column">
        <NavBar direction="row" background="lightblue" pad="small">
            <Anchor icon={<Home />} label="Home" href="/" alignSelf="center" />
            <Button primary label="Click here if you are ready to purchase!" href="https://hello.dubsado.com/public/form/view/5f3440572a5c4bda9b798f53" />
        </NavBar>
        <Box pad="10px" align="center" justify="center" fill="horizontal" direction="column" background="lightblue" height="150px" style={{ minHeight: '150px' }}>
            <Box><Text size={headerSize} color="#FFF">Creative &amp; Confident Tools</Text></Box>
            <Box><Text size="medium" color="#333">Tools for educators</Text></Box>
        </Box>
        <Box flex="grow" overflow="hidden" pad="medium">
            <Switch>
                <Route path={`${path}about`} component={ToolInfoRoutes} />
                <Route exact path={path}>
                    <MainPage path={path} url={url} />
                </Route>
                <Route path={path}>
                    <Box justify="center" align="center" fill><Text size="40px">Sorry, you landed up on an invalid page.</Text></Box>

                </Route>
            </Switch>

        </Box>
        <Footer justify="center">Creative &amp; Confident Tools © 2020</Footer>
    </Box >
}

interface MainPageProps {
    path: string;
    url: string;
}

const FeatureBox = styled(Box)`
width:100%;
align-items: start;
justify-self: start;
`;

const FeatureLink = styled(Link)`
    text-decoration: none;
    color: black;
    &:visited {
        color: black;
    }
    &:hover {
        color: grey;
    }
`;

const MainPage = (props: MainPageProps) => {

    const size = React.useContext(ResponsiveContext);
    const columns = size === "xsmall" ? ["auto", "auto"] : ["auto", "auto", "auto"];
    const gap = size === "xsmall" ? "medium" : "xlarge";
    const imageWidth = "100%"
    return <Box>
        <Paragraph alignSelf="center"><b>If you are looking for Creative &amp; Confident tools that you have <i>already purchased</i>, please use the direct link that was sent to you.</b></Paragraph>
        <Paragraph alignSelf="center">Choose a tool for more details</Paragraph>
        <Grid
            fill={true}
            justifyContent="center"
            alignContent="stretch"
            align="start"
            justify="start"
            gap={gap}
            pad="small"
            columns={columns}
            rows={"auto"}>
            <FeatureBox direction="column">
                <FeatureLink to="/about/birthplan" >
                    <ToolTextLabel>Birth Plan Board</ToolTextLabel>
                    <ImageBox align="start" justify="start"><Image width={imageWidth} src="./images/general/BirthPlan2.png" fit="contain" /></ImageBox>
                </FeatureLink>
            </FeatureBox>

            <FeatureBox direction="column">
                <FeatureLink to="/about/signsoflabor" >
                    <ToolTextLabel>Signs of Labor</ToolTextLabel>
                    <ImageBox align="start"><Image width={imageWidth} src="./images/general/SignsofLabor.png" fit="contain" /></ImageBox>
                </FeatureLink>
            </FeatureBox>

            <FeatureBox direction="column">
                <FeatureLink to="/about/postbirth" >
                    <ToolTextLabel>POST-BIRTH</ToolTextLabel>
                    <ImageBox align="start"><Image width={imageWidth} src="./images/general/POSTBIRTH.png" fit="contain" /></ImageBox>
                </FeatureLink>
            </FeatureBox>

            <FeatureBox direction="column">
                <FeatureLink to="/about/laborpracticewheels" >
                    <ToolTextLabel>Labor Practice Wheels</ToolTextLabel>
                    <ImageBox align="start"><Image width={imageWidth} src="./images/general/Spinners1.png" fit="contain" /></ImageBox>
                </FeatureLink>
            </FeatureBox>

            <FeatureBox direction="column">
                <FeatureLink to="/about/newbornwarningsigns" >
                    <ToolTextLabel>Newborn Warning Signs</ToolTextLabel>
                    <ImageBox align="start"><Image width={imageWidth} src="./images/general/NewbornWarningSigns.png" fit="contain" /></ImageBox>
                </FeatureLink>
            </FeatureBox>
        </Grid></Box >;
}
