import React from "react";
import { Box, Paragraph, Header, Image } from "grommet";
import styled from "styled-components";
import { Route, Switch, useRouteMatch } from "react-router-dom";

const Container = styled(Box)`
  font-size: 30px;
  text-align: left;
  font-weight: bold;
  margin-bottom: 15px;
  margin-top: 20px;
`;

const CustomHeader = styled(Header)`
margin-bottom: 20px;
  text-align: left;
`;

const CustomParagraph = styled(Paragraph)`
max-width: 100%;
`;
const Row = styled(Box)`
    flex-direction: row;
    align-items: start;
`;

const Column = styled(Box)`
    flex-direction: column;
    align-items: start;
`;


function SignsOfLaborInfo() {

    return <Container flex fill direction="column">
        <CustomHeader>Signs of Labor</CustomHeader>
        <CustomParagraph>Signs of labor arranged along the top of the screen with categories below. Items can be dragged down and placed under the appropriate category.
            Send this link to small groups to work together to sort and think through what the start of labor might look like or do it as a whole class activity.</CustomParagraph>
        <CustomParagraph>You can see the "Grab &amp; Go" version here. This tool is also available customized with your choice of color and your own selection of categories and items.</CustomParagraph>
        <Row>
            <Image src="../images/general/SignsofLabor.png" fit="contain" width="50%" />
        </Row>
    </Container>;
}

function PostBirthInfo() {

    return <Container flex fill direction="column">
        <CustomHeader>POST-BIRTH Sort</CustomHeader>
        <CustomParagraph>Information taken from AWHONN’s "POST-BIRTH Warning Signs" handout, this sort allows you to drag the warning signs from the top of the page to their proper position under "Call 911" or "Call your Provider".
            This is a great way to reinforce families’ knowledge after reviewing this information with them or sharing the handout.</CustomParagraph>
        <CustomParagraph>You can see the "Grab &amp; Go" version here. This tool is also available customized with your choice of color and your own selection of categories and items.</CustomParagraph>
        <Row>
            <Image src="../images/general/POSTBIRTH.png" fit="contain" width="50%" />
        </Row>
    </Container>;
}

function LaborPracticeWheelsInfo() {

    return <Container flex fill direction="column">
        <CustomHeader>Labor Practice Wheels</CustomHeader>
        <CustomParagraph>Practice makes perfect! 3 side-by-side spinners with 8 options on each wheel. Wheels spin with the click of a button. Selections are written below each wheel and automatically removed from the spinner (so they won’t be repeated on the next spin). 
          Page can be refreshed to add all options back on the wheel</CustomParagraph>
        <CustomParagraph>You can see the "Grab &amp; Go" version here. This tool is also available customized with your choice of colors and your own selection of 16 preferences.</CustomParagraph>
        <Column>
            <Image src="../images/general/Spinners1.png" fit="contain" width="50%" />
            { <Row>
                <Image src="../images/general/Spinners2.png" fit="contain" width="33%" />
                <Image src="../images/general/Spinners3.png" fit="contain" width="33%" />
                <Image src="../images/general/Spinners4.png" fit="contain" width="33%" />
            </Row> }
        </Column>
    </Container >
}

function BirthPlanBoard() {
    return <Container flex fill direction="column">
        <CustomHeader>Birth Plan Board</CustomHeader>

        <CustomParagraph>4x4 grid of two-sided cards. Cards can be clicked to flip between the sides.
        Send the link to class attendees so that they can map out their ideal choices.
            Use these as you would a birth plan card sort in class. The grid shifts with changing screen size so that cards remain the same size.</CustomParagraph>
        <CustomParagraph>You can see the "Grab &amp; Go" version here. This tool is also available customized with your choice of colors and your own selection of 16 preferences.</CustomParagraph>
        <Row>
            <Image src="../images/general/BirthPlan1.png" fit="contain" width="50%" />
            <Image src="../images/general/BirthPlan2.png" fit="contain" width="50%" />
        </Row>
    </Container >
}


function NewbornSignsInfo() {

    return <Container flex fill direction="column">
        <CustomHeader>Newborn Warning Signs Activity</CustomHeader>
        <CustomParagraph>6x6 grid of newborn behaviors, appearances or conditions.
        All sections are white when initially loaded, and then can be clicked to toggle between red (get some help) and green (normal/no big deal).
            Grid will return to all white if the page is refreshed.Have families work together in breakout rooms or run this activity with the whole class.</CustomParagraph>
        <CustomParagraph>You can see the "Grab &amp; Go" version here.
            This tool is also available customized with your choice of toggle colors and alteration of text.</CustomParagraph>
        <Row>
            <Image src="../images/general/NewbornWarningSigns.png" fit="contain" width="50%" />
        </Row>
    </Container>
}


function TokenSortInfo() {

    return <Container flex fill direction="column">
        <CustomHeader>Token Sort</CustomHeader>
        <CustomParagraph>Info goes here</CustomParagraph>
    </Container>
}


export function ToolInfoRoutes() {

    let { path } = useRouteMatch();
    return <Switch>
        <Route path={`${path}/birthplan`} component={BirthPlanBoard} />
        <Route path={`${path}/signsoflabor`} component={SignsOfLaborInfo} />
        <Route path={`${path}/postbirth`} component={PostBirthInfo} />
        <Route path={`${path}/laborpracticewheels`} component={LaborPracticeWheelsInfo} />
        <Route path={`${path}/newbornwarningsigns`} component={NewbornSignsInfo} />
        <Route path={`${path}/tokensort`} component={TokenSortInfo} />
    </Switch>;
}
