import React, { useState, CSSProperties } from "react";
import { Box, Grid, ResponsiveContext, GridProps, Text, Image } from "grommet";
import { TokenSortStore, TokenSortConfig, TokenData } from "../stores/tokenSortStore";
import styled from "styled-components";
import Backend from 'react-dnd-html5-backend';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { ClickToEdit } from "./ClickToEdit";

const ZoneBox = styled(Box)`
    border: 0.5px solid #000;
    color: black;
    text-align: center;
    justify-content: start;
    padding: 5px;
    min-height: 90px;
    `;

const FreeZoneBox = styled(Box)`
    color: black;
    text-align: center;
    justify-content: start;
    padding: 5px;
    margin-right:5px;
    `;

interface TokenItem {
    type: string
    token: TokenData;
}

interface Dict<V> {
    [key: string]: V;
}

const tokenImages: Dict<string> = {
    "orange": "../../images/assets/orangeButton.png",
    "lightblue": "../../images/assets/lightBlueButton.png",
    "blue": "../../images/assets/blueButton.png",
    "green": "../../images/assets/greenButton.png",
    "lightgreen": "../../images/assets/lightGreenButton.png",
    "purple": "../../images/assets/purpleButton.png",
    "lightpurple": "../../images/assets/lightPurpleButton.png",
    "red": "../../images/assets/redButton.png",
    "yellow": "../../images/assets/yellowButton.png",
    "lightyellow": "../../images/assets/lightYellowButton.png",
    "pink": "../../images/assets/pinkButton.png",
    "gray": "../../images/assets/grayButton.png"
}

type ResponsiveGridProps = { size: string, children: any, configuration: TokenSortConfig } & GridProps;
const ResponsiveGrid = (props: ResponsiveGridProps) => {

    const dim = props.configuration.zoneCount <= 1 ? 1 : Math.ceil(Math.sqrt(props.configuration.zoneCount));

    const size = "flex";
    const gridDims = Array(dim).fill(0).map(x => size);
    return (
        <Grid
            fill={true}
            justifyContent="center"
            alignContent="stretch"
            align="center"
            justify="center"
            gap="small"
            margin="small"
            rows={gridDims}
            columns={gridDims}
            {...props}>
            {props.children}
        </Grid>
    );
}


const sizeStr = (size?: ("small" | "default")) => size === "small" ? "3vw" : "min(5vw, 5vh)";

interface TokenItemHolderProps {
    configuration: TokenSortConfig;
    token: TokenData | null;
    size?: ("small" | "default")
}
const TokenHolder: React.FC<TokenItemHolderProps> = React.memo((props: TokenItemHolderProps) => {
    if (!props.token) {
        return <Box width={sizeStr(props.size)}></Box>;
    }
    else {
        return <Token token={props.token} size={props.size} />;
    }
});

interface TokenItemProps {
    token: TokenData;
    size?: ("small" | "default")
}
const Token: React.FC<TokenItemProps> = React.memo((props: TokenItemProps) => {
    const [{ isDragging }, drag] = useDrag({
        item: { type: 'token', token: props.token },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        })
    });

    if (isDragging) {
        return <Box width={sizeStr(props.size)}></Box>;
    }

    const image = tokenImages[props.token.color];
    return <Box width={sizeStr(props.size)} ref={drag}>
        <Image width="100%" src={image} fit="contain" />
    </Box>;
});

interface ZoneTextChange {
    id: number;
    text?: string;
}

interface TokenPositionChange {
    token: TokenData;
    to: number;
}

interface TokenZoneBaseProps {
    tokens: TokenData[];
    id: number;
    configuration: TokenSortConfig;
    onTokenChange: (data: TokenPositionChange) => void;
    onTextChange?: (data: ZoneTextChange) => void;
}

interface TokenZoneProps extends TokenZoneBaseProps {
    text?: string;
}
const Zone: React.FC<TokenZoneProps> = React.memo((props: TokenZoneProps) => {
    const [{ canDrop, isOver }, drop] = useDrop({
        accept: "token",
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        }),
        canDrop(item: TokenItem, monitor) {
            return item.token.location !== props.id
        },
        drop(item: TokenItem, monitor) {
            props.onTokenChange({ token: item.token, to: props.id });
            return undefined;
        }
    });

    const onTextChange = (text?: string) => {

        if (props.onTextChange) {
            props.onTextChange({ text: text, id: props.id });
        }
    };

    const isActive = canDrop && isOver;
    let zoneBoxStyleOverride: CSSProperties | undefined = undefined;
    if (isActive) {
        zoneBoxStyleOverride = { border: '2px solid #000' };
    }

    const tokenBoxes = props.tokens.map(x => {
        return <Token token={x} key={x.id} size="small" />
    });

    const border = props.tokens.length > 0 ? "1px dotted #000" : "none";
    return <ZoneBox fill ref={drop} style={zoneBoxStyleOverride}>
        <Box flex={{ shrink: 0, grow: 0 }} direction="row" wrap={true} style={{ borderBottom: border, paddingBottom: '2px' }}>
            {tokenBoxes}
        </Box>
        <Box flex style={{ marginTop: "2vh", lineHeight: '15px', wordBreak: 'break-word' }} >
            <ClickToEdit fontSize="2vw" onEndEditing={onTextChange} initialValue={props.text} />
        </Box>
    </ZoneBox >;
});


interface FreeTokenZoneProps extends TokenZoneBaseProps {
    startingTokenId: number;
}
const FreeTokenZone: React.FC<FreeTokenZoneProps> = React.memo((props: FreeTokenZoneProps) => {
    const freeTokenBoxes =
        Array.from({ length: props.configuration.tokenCount }, (x, i) => i + props.startingTokenId).map(x => {
            const matches = props.tokens.filter(t => t.id === x);
            const token = matches.length > 0 ? matches[0] : null;
            return <TokenHolder configuration={props.configuration} token={token} key={x} />
        });

    const [{ canDrop, isOver }, drop] = useDrop({
        accept: "token",
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        }),
        canDrop(item: TokenItem, monitor) {
            return item.token.location !== props.id && (props.id > 0 || item.token.startingZone === props.id);
        },
        drop(item: TokenItem, monitor) {
            props.onTokenChange({ token: item.token, to: props.id });
            return undefined;
        }
    });

    const isActive = canDrop && isOver;
    let zoneBoxStyleOverride: CSSProperties | undefined = undefined;
    if (isActive) {
        zoneBoxStyleOverride = { border: '2px solid #000' };
    }

    return <FreeZoneBox fill ref={drop} style={zoneBoxStyleOverride}>
        <Box direction="row" wrap={true} margin="10px">
            {freeTokenBoxes}
        </Box>
    </FreeZoneBox>;
});

export type TokenSortProps = {
    configuration: TokenSortConfig
    store: TokenSortStore
}

export const TokenSort: React.FC<TokenSortProps> = (props: TokenSortProps) => {

    const globalState = props.store.getState(props.configuration);
    const [componentState, setComponentState] = useState(globalState);
    const size = React.useContext(ResponsiveContext);


    const getZone = (id: number) => {

        return globalState.zones.filter(x => x.id === id)[0];
    }

    const onTextChange = (data: ZoneTextChange) => {

        const zone = getZone(data.id);
        if (zone) {
            zone.text = data.text;
            setComponentState({ ...globalState });
        }
    }

    const onTokenChange = (data: TokenPositionChange) => {
        var toZone = getZone(data.to);
        var fromZone = getZone(data.token.location);

        data.token.location = toZone.id;
        toZone.tokens.push(data.token);
        const indexToRemove = fromZone.tokens.findIndex(x => x.id === data.token.id);
        fromZone.tokens.splice(indexToRemove, 1);

        setComponentState({ ...globalState });
    }


    // Create box for each  choice
    const zoneBoxes = globalState.zones
        .filter(zone => zone.id > 0) // Filter to box zones
        .map(zone => {
            return <Zone key={zone.id}
                configuration={props.configuration}
                id={zone.id}
                text={zone.text}
                tokens={zone.tokens}
                onTokenChange={onTokenChange}
                onTextChange={onTextChange} />
        });


    const freeZones = componentState.zones
        .filter(zone => zone.id < 0) // Filter to free zones
        .map(zone => {
            return <FreeTokenZone key={zone.id} configuration={props.configuration} startingTokenId={zone.startingTokenId!} id={zone.id} tokens={zone.tokens} onTokenChange={onTokenChange} />
        })

    return <DndProvider backend={Backend}><Box direction="row" fill>
        <Box width="70vw" margin={{ right: '4vw' }}>
            <ResponsiveGrid configuration={props.configuration} size={size} >
                {zoneBoxes}
            </ResponsiveGrid>
        </Box>
        <Box width="30vw" align="center" direction="column" margin={{ top: '10px', right: '10px' }} >

            <Text>{props.configuration.title}</Text>
            {freeZones}
        </Box>
    </Box ></DndProvider>
};