import React, { useState, useEffect } from "react";
import { CardSort } from "./CardSort";
import { Switch, Route, Link, useRouteMatch } from "react-router-dom";
import { Box } from "grommet";
import styled from "styled-components";
import { CardSortStore, CardSortConfig } from "../stores/cardSortStore";
import { FlipBoardStore, FlipBoardConfig } from "../stores/flipBoardStore";
import { FlipBoard } from "./FlipBoard";

// Config files
import accountsConfig from "../configurations/accounts.json"
import { ColorBoardStore, ColorBoardConfig } from "../stores/colorBoardStore";
import { ColorBoard } from "./ColorBoard";
import { WheelBoardConfig, WheelBoardStore } from "../stores/wheelStore";
import { WheelBoard } from "./WheelBoard";
import { IStore, BaseConfig } from "../stores/BaseStore";
import { TokenSortStore, TokenSortConfig } from "../stores/tokenSortStore";
import { TokenSort } from "./TokenSort";

const StyledBox = styled(Box)`
`;

const SectionBox = styled(Box)`
    width:500px;
`;

const StyledLink = styled(Link)`
  font-weight: bold;
  text-decoration: none;
  font-size: 17px;
  color: ${props => props.theme.global.colors['neutral-3']};
  &:active, &:visited {
    color: ${props => props.theme.global.colors['neutral-3']};
    border: none;
  }
  &:hover {
    color: ${props => props.theme.global.colors['neutral-4']};
  }
`;

type StoreConfig = {
    Tool: string,
    Store: IStore,
    Factory: (config: BaseConfig) => JSX.Element
}

const storeMap: Map<string, StoreConfig> = new Map();
const toolMapItems: StoreConfig[] = [
    {
        Tool: "cardSort",
        Store: CardSortStore.Instance,
        Factory: (config: BaseConfig) => <CardSort configuration={config as CardSortConfig} store={CardSortStore.Instance} />
    },
    {
        Tool: "colorBoard",
        Store: ColorBoardStore.Instance,
        Factory: (config: BaseConfig) => <ColorBoard configuration={config as ColorBoardConfig} store={ColorBoardStore.Instance} />
    },
    {
        Tool: "flipBoard",
        Store: FlipBoardStore.Instance,
        Factory: (config: BaseConfig) => <FlipBoard configuration={config as FlipBoardConfig} store={FlipBoardStore.Instance} />
    },
    {
        Tool: "wheelSpinner",
        Store: WheelBoardStore.Instance,
        Factory: (config: BaseConfig) => <WheelBoard configuration={config as WheelBoardConfig} store={WheelBoardStore.Instance} />
    },
    {
        Tool: "tokenSort",
        Store: TokenSortStore.Instance,
        Factory: (config: BaseConfig) => <TokenSort configuration={config as TokenSortConfig} store={TokenSortStore.Instance} />
    }
];

toolMapItems.forEach(item => {
    storeMap.set(item.Tool.toLowerCase(), item);
});


interface Account {
    url: string,
    name: string,
    defaultTools: string[],
    customTools: string[]
}

interface AccountInfo {
    account: Account;
    configs: ToolConfigResult[]
}

export interface PageRouterProps {
    name: string
}

interface ToolConfigResult {
    defaultTool: string | null;
    config: BaseConfig[];
}

async function fetchConfig(name: string, tool: string): Promise<ToolConfigResult> {

    const storeEntity = storeMap.get(tool.toLowerCase());
    const response = await fetch(`/configurations/${name}/${storeEntity?.Tool || tool}.json`);
    const json = await response.json();
    let defaultTool: (string | null) = tool;
    if (!storeEntity) {
        console.log(`Could not find tool name ${tool}, will check per tool config`);
        defaultTool = null;
    }

    return {
        config: json, defaultTool: defaultTool
    };
}

function arrayContains(array: string[], value: string) {
    if (!array || !value) { return false; }
    value = value.toLowerCase();
    var res = array.find(x => x.toLowerCase() === value);
    return !!res;
}

const defaultToolSetName = "default";
export const PageRouter = (props: PageRouterProps) => {
    let { path, url } = useRouteMatch();

    const [accountInfo, setAccountInfo] = useState<AccountInfo | null>(null);

    useEffect(() => {

        const accounts: Account[] = accountsConfig as Account[];
        if (!accounts) {
            return;
        }

        const name = props.name.toLowerCase();
        let foundAccount: Account | null = null;
        for (let account of accounts) {
            if (name === account.url.toLowerCase()) {
                foundAccount = account;
                break;
            }
        }

        if (!foundAccount) {
            return;
        }

        foundAccount.customTools = foundAccount.customTools || [];
        foundAccount.defaultTools = foundAccount.defaultTools || [];

        var promises: Promise<ToolConfigResult>[] = [];
        for (let tool of foundAccount.customTools) {
            const promise = fetchConfig(foundAccount.url, tool);
            promises.push(promise);
        }
        for (let tool of foundAccount.defaultTools) {

            // Ignore tools already configured for custom
            if (arrayContains(foundAccount.customTools, tool)) {
                continue;
            }

            const promise = fetchConfig(defaultToolSetName, tool);
            promises.push(promise);
        }

        Promise.allSettled(promises).then(results => {

            let toolConfigs: ToolConfigResult[] = [];
            for (let res of results) {
                if (res.status === 'rejected') {
                    console.error(`Config call failed: ${res.reason}`);

                } else {
                    toolConfigs.push(res.value);
                }
            }
            setAccountInfo({ account: foundAccount!, configs: toolConfigs });

        });
    }, [props.name]);


    if (!accountInfo) {
        return null;
    }

    const elements = getElements(url, path, accountInfo);

    return (
        <StyledBox fill={true} flex align='center'  >
            <Switch>
                {elements.routes}
                <Route path="/">
                    <StyledBox flex={false} fill={false} width="auto" pad="medium" margin="small" align='center' round="large" elevation="medium" border={{
                        "color": "lightgrey",
                        "size": "medium",
                        "style": "solid",
                        "side": "all"
                    }}  >
                        <h1>{accountInfo.account.name}'s Tools</h1>
                        {elements.sections}
                    </StyledBox>
                </Route>
            </Switch></StyledBox>);
}


export interface PageSections {
    section?: JSX.Element;
    routes?: JSX.Element[];
}
interface Dict<V> {
    [key: string]: V;
}

const getElements = (url: string, path: string, accountInfo: AccountInfo) => {
    url = url.replace(/\/$/, "")
    if (!accountInfo || !accountInfo.configs) {
        return { routes: [], sections: [] };
    }

    let allRoutes: (JSX.Element | null)[] = [];
    let allSections: (JSX.Element | null)[] = [];
    let sectionPerTool: Dict<(JSX.Element | null)[]> = {}
    for (let configResult of accountInfo.configs) {

        let storeEntity: (StoreConfig | undefined);

        // If we have a default tool, then its the old model and we know the tool type
        // based on the file name.
        if (configResult.defaultTool) {
            storeEntity = storeMap.get(configResult.defaultTool.toLowerCase());
            if (!storeEntity) {
                console.error(`Could not find tool ${configResult.defaultTool} in store map during default route building`);
                continue;
            }
        }

        let routes = configResult.config.map(x => {

            let localStoreEntity = storeEntity;
            if (!localStoreEntity) {
                if (x.tool) {
                    localStoreEntity = storeMap.get(x.tool.toLowerCase());
                    if (!localStoreEntity) {
                        console.error(`Could not find tool ${x.tool} in store map during local route building`);
                        return null;
                    }
                }
                else {
                    console.error(`Tool not specified in non-default tool named file`);
                    return null;
                }
            }

            if (!sectionPerTool[localStoreEntity.Tool]) {
                sectionPerTool[localStoreEntity.Tool] = [];
            }

            const displayLink = <li style={{ marginBottom: '8px' }} key={x.name}><StyledLink key={x.name} to={`${url}/${x.name}`}>
                {x.title}
            </StyledLink></li>;
            sectionPerTool[localStoreEntity.Tool].push(displayLink);

            return <Route key={x.name} path={`${url}/${x.name}`}>
                {localStoreEntity.Factory(x)}
            </Route>;
        });
        allRoutes = allRoutes.concat(routes);
    }


    for (const tool of Object.keys(sectionPerTool)) {
        let storeEntity = storeMap.get(tool.toLowerCase());
        if (!storeEntity) {
            console.error(`Could not find tool ${tool} in store map during section building`);
            continue;
        }

        const links = sectionPerTool[tool];

        const section = <SectionBox key={storeEntity.Store.displayName}><h2>{storeEntity.Store.displayName} </h2>
            <ul>
                {links}
            </ul>
        </SectionBox>;

        allSections = allSections.concat(section);
    }

    return { routes: allRoutes, sections: allSections };
}
